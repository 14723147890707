import { Cart, ProductInCart, ProductService } from '@store/modules/cart/types'
import {
    CheckoutCartSummary,
    ProductInCheckout
} from '@store/modules/checkout/types'
import { CampaignData } from '@store/modules/home/types'
import { OrderProduct } from '@store/modules/order/types'
import {
    DetailedProduct,
    DetailedProductSku
} from '@store/modules/product/types'
import { ProductInSearch } from '@store/modules/search/types'
import {
    formatPriceStringToHaveOnePoint,
    getPriceValueOrEmptyString,
    getSpotPrice,
    ifZeroChangeToOne,
    returnTotalPriceIfInstallmentEqualsZero
} from '@utils/product'
import { castToNumberOrEmptyIfEqualsZero } from '@utils/resultsContainer'
import {
    EMPTY_GTM_FORMATTED_BANNER,
    EMPTY_GTM_FORMATTED_PRODUCT
} from './constants'

import {
    CategoryDetailsProps,
    CartEcommerceGtm,
    GTMFormatedCartProduct as GTMFormattedCartProduct,
    GTMFormatedProduct,
    ProductEcommerceGtm,
    ProductGtm,
    CheckoutEcommerceGtm,
    CheckoutDeliveryEcommerceGtm
} from './types'
import { Order } from '@store/modules/order/types'

export const getProductCategoryDetailsData = (
    product: CategoryDetailsProps
) => {
    const categoryObject = []

    if (product.categoria && product.idCategoria) {
        categoryObject.push({
            id: product.idCategoria?.toString(),
            name: product.categoria
        })
    }

    if (product.subcategoria && product.idSubcategoria) {
        categoryObject.push({
            id: product.idSubcategoria?.toString(),
            name: product.subcategoria
        })
    }

    return categoryObject
}

function isExtendedWarranty(name: string) {
    return name.includes('Garantia estendida:')
}
const isAtacarejoAndHaventMinQuantity = (
    minQuantityForAtacarejoPrice: number,
    quantidade: number
) => {
    return quantidade < minQuantityForAtacarejoPrice
}

export function getProductCartPrice(product: ProductInCart) {
    const minQuantityForAtacarejoPrice = product.preco.atacarejo.quantidade
    if (
        minQuantityForAtacarejoPrice === 0 ||
        isAtacarejoAndHaventMinQuantity(
            minQuantityForAtacarejoPrice,
            product.quantidade
        )
    )
        return Number(formatPriceStringToHaveOnePoint(product.preco.precoPor))
    else return product.preco.atacarejo.precoUnitario
}

export function getPrice(price: string | number) {
    if (Number(price) > 0) return price
    else return ''
}

export const formatProductForGTM = (
    product: ProductGtm
): GTMFormatedProduct => {
    const categoryDetails = [
        {
            id: product.idCategoria,
            name: product.categoria
        },
        {
            id: product.idSubcategoria,
            name: product.subcategoria
        }
    ]

    return {
        available: `${product.disponivel}`,
        brand: product.marca,
        category: formatCategoryDescription(product),
        categoryDetails: categoryDetails.filter(category => category.id),
        fullImage: product.fullImage,
        fullPrice: product.fullPrice,
        id: product.sku,
        installment: {
            count: product.contagemParcelas,
            price: product.precoParcelas
        },
        name: product.nome,
        price: getPrice(product.precoPor || ''),
        productPartNumber: product.idProdutoPai,
        sku: product.sku,
        parentSku: product.skuPai,
        thumbnail: product.thumbnail,
        wcsId: product.idProdutoPai
    }
}

export const formatCategoryDescription = (
    product: ProductInCart | ProductInCheckout | ProductGtm
) => {
    if (product.categoria && product.subcategoria) {
        return `${product.categoria}|${product.subcategoria}`
    } else if (product.categoria) {
        return `${product.categoria}`
    } else if (product.subcategoria) {
        return `${product.subcategoria}`
    } else {
        return ''
    }
}

const insertFormatedCheckoutProduct = (product: ProductInCheckout) => {
    return {
        brand: product.marca ?? 'Garantia',
        category: formatCategoryDescription(product),
        categoryDetails: getProductCategoryDetailsData(product),
        fullImage: product.thumbnail,
        fullPrice: product.valorUnitario,
        id: product.sku,
        name: product.nome,
        price: product.valorUnitario,
        sku: product.sku,
        thumbnail: product.thumbnail,
        wcsId: product.idProduto?.toString() ?? '',
        quantity: product.quantidade
    }
}

const formatCartProduct = (product: ProductInCart) => {
    return {
        brand: product.marca,
        category: formatCategoryDescription(product),
        categoryDetails: getProductCategoryDetailsData(product),
        fullImage: product.thumbnail,
        fullPrice: Number(
            formatPriceStringToHaveOnePoint(product.preco.precoCheio)
        ),
        id: product.sku,
        name: product.nome,
        price: getProductCartPrice(product),
        sku: product.sku,
        thumbnail: product.thumbnail,
        quantity: product.quantidade,
        wcsId: product.idProduto.toString()
    }
}

const formatServicesProductCartProduct = (service: ProductService) => {
    return {
        brand: 'Garantia',
        category: '',
        thumbnail: service.thumbnail,
        fullImage: service.thumbnail,
        id: service.sku,
        sku: service.sku,
        name: service.nome,
        price: Number(service.valor),
        quantity: service.quantidade,
        wcsId: service.idProduto.toString()
    }
}

const hasServicesInProduct = (service: ProductService) => {
    return service.quantidade > 0
}

export const formatCartProductsForGTM = (
    products: ProductInCart[] = []
): GTMFormattedCartProduct[] => {
    try {
        return products.reduce<GTMFormattedCartProduct[]>(
            (formatedProducts, product) => {
                if (product.disponivel) {
                    const productWithService =
                        product.servicosDisponiveis.filter(
                            hasServicesInProduct
                        )[0]

                    if (productWithService) {
                        formatedProducts.push(
                            formatCartProduct(product),
                            formatServicesProductCartProduct(productWithService)
                        )
                    } else {
                        formatedProducts.push(formatCartProduct(product))
                    }
                }
                return formatedProducts
            },
            []
        )
    } catch (error) {
        console.warn(error)
        return []
    }
}

export const formatProductsPaymentForGTM = (
    products: ProductInCheckout[] = []
): GTMFormatedProduct[] => {
    try {
        return products.reduce<GTMFormatedProduct[]>(
            (formatedProduct, product) => {
                if (isExtendedWarranty(product.nome)) {
                    formatedProduct.push({
                        brand: 'Garantia',
                        category: '',
                        categoryDetails: [],
                        fullImage: product.thumbnail,
                        id: `${product.sku}`,
                        name: product.nome,
                        price: product.valorUnitario,
                        quantity: product.quantidade,
                        sku: product.sku,
                        thumbnail: product.thumbnail,
                        wcsId: `${product.idProduto}`
                    })
                } else if (product.valorTotalText === 'Brinde') {
                    formatedProduct.push({
                        id: product.sku,
                        sku: product.sku,
                        wcsId: `${product.idProduto}`,
                        name: product.nome,
                        brand: product.marca,
                        fullPrice: '',
                        price: product.valorUnitario,
                        categoryDetails: [
                            {
                                id: product.idCategoria,
                                name: product.categoria
                            },
                            {
                                id: product.idSubcategoria,
                                name: product.subcategoria
                            }
                        ],
                        category:
                            product.categoria && product.subcategoria
                                ? `${product.categoria}/${product.subcategoria}`
                                : '',
                        thumbnail: product.thumbnail,
                        fullImage: product.thumbnail,
                        quantity: product.quantidade
                    })
                } else {
                    formatedProduct.push({
                        brand: product.marca,
                        category: product.categoria,
                        categoryDetails: getProductCategoryDetailsData(product),
                        fullImage: product.thumbnail,
                        fullPrice: `${product.valorUnitario}`,
                        id: `${product.sku}`,
                        name: product.nome,
                        price: product.valorUnitario,
                        quantity: product.quantidade,
                        sku: product.sku,
                        thumbnail: product.thumbnail,
                        wcsId: `${product.idProduto}`
                    })
                }

                return formatedProduct
            },
            []
        )
    } catch (error) {
        console.warn(error)
        return []
    }
}
export const formatCheckoutProductsForGTM = (
    products: ProductInCheckout[] = []
): GTMFormattedCartProduct[] => {
    try {
        return products.reduce<GTMFormattedCartProduct[]>(
            (formatedProduct, product) => {
                formatedProduct.push(insertFormatedCheckoutProduct(product))
                return formatedProduct
            },
            []
        )
    } catch (error) {
        console.warn(error)
        return []
    }
}

export const formatSearchResponseProducts = (produtos: ProductInSearch[]) => {
    try {
        return produtos?.map(product => {
            return formatProductForGTM({
                disponivel: product.disponivel,
                marca: product.marca,
                categoria: product.categoria,
                subcategoria: product.subcategoria,
                idCategoria: product.idCategoria,
                idSubcategoria: product.idSubcategoria,
                thumbnail: product.thumbnail || '',
                fullImage: product.thumbnail,
                fullPrice: castToNumberOrEmptyIfEqualsZero(
                    getPriceValueOrEmptyString(
                        product.preco.precoDe?.toString() || ''
                    )
                ),
                preco: product.preco,
                idProdutoPai: product.idProdutoPai,
                idProduto: String(product.idProduto || ''),
                sku: product.sku,
                nome: product.nome,
                contagemParcelas: ifZeroChangeToOne(
                    product.preco.parcelas?.numeroParcelas || 0
                ).toString(),
                precoParcelas: returnTotalPriceIfInstallmentEqualsZero(
                    product.preco
                ),
                precoPor: Number(
                    getPriceValueOrEmptyString(
                        product.preco.precoPor?.toString() || ''
                    )
                )
            })
        })
    } catch (error) {
        console.warn(error)
        return []
    }
}

export const formatProductDetailForPDP = (
    currentProductInfo: DetailedProductSku,
    staticProductData: DetailedProduct
) => {
    try {
        return formatProductForGTM({
            disponivel: currentProductInfo.disponivel,
            marca: staticProductData.marca,
            categoria: staticProductData.categoria,
            subcategoria: staticProductData.subcategoria,
            idCategoria: staticProductData.idCategoria,
            idSubcategoria: staticProductData.idSubcategoria,
            thumbnail: staticProductData.thumbnail || '',
            fullImage: staticProductData.skus[0].imagens?.at(0)?.url360 || '',
            preco: currentProductInfo.preco,
            fullPrice: formatPriceStringToHaveOnePoint(
                currentProductInfo.preco.precoDe.toString()
            ),
            idProdutoPai: staticProductData.idProdutoPai.toString(),
            idProduto: currentProductInfo.idProduto.toString(),
            sku: currentProductInfo.sku,
            skuPai: staticProductData.skuPai,
            nome: currentProductInfo.nome,
            contagemParcelas: ifZeroChangeToOne(
                currentProductInfo.preco.parcelas.numeroParcelas
            ),
            precoParcelas: returnTotalPriceIfInstallmentEqualsZero(
                currentProductInfo.preco
            ),
            precoPor: Number(
                getPriceValueOrEmptyString(
                    getSpotPrice({
                        percentualDescontoAVista:
                            currentProductInfo.preco.percentualDescontoAVista ??
                            0,
                        precoCheioText:
                            currentProductInfo.preco.precoCheioText ?? '',
                        precoPorText:
                            currentProductInfo.preco.precoPorText ?? '',
                        precoPrazoText:
                            currentProductInfo.preco.precoPrazoText ?? ''
                    })
                )
            )
        })
    } catch (error) {
        console.warn(error)
        return EMPTY_GTM_FORMATTED_PRODUCT
    }
}

export const formatProductsEcommerceForConclusion = (
    products?: OrderProduct[]
) => {
    if (!products?.length) return []
    try {
        return products.map((product, index) => ({
            item_id: product.sku,
            item_name: product.nome,
            affiliation: product.lojista,
            index: index,
            item_brand: product.marca,
            item_category: product.categoria,
            item_category2: product.subcategoria,
            item_list_id: product.idItemPedido,
            item_list_name: product.nome,
            item_variant: '',
            quantity: product.quantidade,
            price: product.valorUnitario
        }))
    } catch (error) {
        console.warn(error)
        return [EMPTY_GTM_FORMATTED_PRODUCT]
    }
}

export const formatEcommerceForPDP = (
    currentProductInfo: DetailedProductSku,
    staticProductData: DetailedProduct
): ProductEcommerceGtm[] => {
    try {
        return [
            {
                item_brand: staticProductData.marca,
                item_id: currentProductInfo.sku,
                item_category: staticProductData.categoria,
                item_category2: staticProductData.subcategoria,
                item_name: currentProductInfo.nome,
                price: Number(
                    getPriceValueOrEmptyString(
                        getSpotPrice({
                            percentualDescontoAVista:
                                currentProductInfo.preco
                                    .percentualDescontoAVista ?? 0,
                            precoCheioText:
                                currentProductInfo.preco.precoCheioText ?? '',
                            precoPorText:
                                currentProductInfo.preco.precoPorText ?? '',
                            precoPrazoText:
                                currentProductInfo.preco.precoPrazoText ?? ''
                        })
                    )
                )
            }
        ]
    } catch (error) {
        console.warn(error)
        return [EMPTY_GTM_FORMATTED_PRODUCT]
    }
}

export const formatCartProductsForEcommerceItems = (
    cartItems: ProductInCart[]
): ProductEcommerceGtm[] => {
    return cartItems.map((product, index) => ({
        item_id: product.idProduto,
        item_name: product.nome,
        affiliation: product.lojista,
        index: index,
        item_brand: product.marca,
        item_category: product.categoria,
        item_category2: product.subcategoria,
        item_list_id: product.idItemPedido,
        item_list_name: product.nome,
        price: product.preco
            ? Number(
                  getPriceValueOrEmptyString(
                      getSpotPrice({
                          percentualDescontoAVista:
                              product.preco.percentualDescontoAVista ?? 0,
                          precoCheioText: product.preco.precoCheioText ?? '',
                          precoPorText: product.preco.precoPorText ?? '',
                          precoPrazoText: product.preco.precoPrazoText ?? ''
                      })
                  )
              )
            : null,
        quantity: product.quantidade
    }))
}

export const formatEcommerceForCart = (productCart: Cart): CartEcommerceGtm => {
    try {
        const itemsCart = formatCartProductsForEcommerceItems(
            productCart.itensPedido
        )

        const cupons = productCart?.cupons?.map(cupom => cupom.codigo)
        const codigosCupons = cupons?.join(',') || ''

        return {
            currency: 'BRL',
            value: Number(productCart.valorTotalProdutos),
            coupon: codigosCupons,
            items: itemsCart
        }
    } catch (error) {
        console.warn(error)
        return {
            currency: 'BRL',
            value: 0,
            coupon: '',
            items: []
        }
    }
}

export const formatEcommerceForCheckoutPayment = (
    cartSummary: CheckoutCartSummary,
    paymentMethod: string
): CheckoutEcommerceGtm => {
    try {
        const itemsCart = cartSummary.itensPedido?.map((product, index) => ({
            item_id: product.idProduto,
            item_name: product.nome,
            affiliation: product.lojista,
            index: index,
            item_brand: product.marca,
            item_category: product.categoria,
            item_category2: product.subcategoria,
            item_list_id: product.idItemPedido,
            item_list_name: product.nome,
            price: product.valorUnitario,
            quantity: product.quantidade
        }))
        const cupons = cartSummary?.cupons?.map(cupom => cupom.codigo)
        const codigosCupons = cupons?.join(',') || ''

        return {
            currency: 'BRL',
            value: cartSummary.valorTotalProdutos ?? 0,
            coupon: codigosCupons,
            items: itemsCart,
            payment_type: paymentMethod
        }
    } catch (error) {
        console.warn(error)
        return {
            currency: 'BRL',
            value: 0,
            coupon: '',
            items: [],
            payment_type: ''
        }
    }
}

export const formatEcommerceForCheckoutDelivery = (
    cartSummary: CheckoutCartSummary,
    deliveryOption: string
): CheckoutDeliveryEcommerceGtm => {
    try {
        const itemsCart = cartSummary.itensPedido?.map((product, index) => ({
            item_id: product.idProduto,
            item_name: product.nome,
            affiliation: product.lojista,
            index: index,
            item_brand: product.marca,
            item_category: product.categoria,
            item_category2: product.subcategoria,
            item_list_id: product.idItemPedido,
            item_list_name: product.nome,
            price: product.valorUnitario,
            quantity: product.quantidade
        }))

        const cupons = cartSummary?.cupons?.map(cupom => cupom.codigo)
        const codigosCupons = cupons?.join(',') || ''

        return {
            currency: 'BRL',
            value: cartSummary.valorTotalProdutos ?? 0,
            coupon: codigosCupons,
            items: itemsCart,
            shipping_tier: deliveryOption
        }
    } catch (error) {
        console.warn(error)
        return {
            currency: 'BRL',
            value: 0,
            coupon: '',
            items: [],
            shipping_tier: ''
        }
    }
}

export const formatConclusionProductsForGTM = (
    products: OrderProduct[] = []
) => {
    try {
        return products.reduce<GTMFormattedCartProduct[]>(
            (formatedProduct, product) => {
                if (product.marca === 'Garantia') {
                    formatedProduct.push({
                        id: product.sku,
                        sku: product.sku,
                        wcsId: `${product.idProduto}`,
                        name: product.nome,
                        brand: product.marca,
                        fullPrice: '',
                        price: product.valorUnitario,
                        categoryDetails: [],
                        category: product.categoria,
                        thumbnail: product.thumbnail,
                        fullImage: product.thumbnail,
                        quantity: product.quantidade,
                        adjustment: product.descontoItem
                    })
                } else if (product.valorTotalText === 'Brinde') {
                    formatedProduct.push({
                        id: product.sku,
                        sku: product.sku,
                        wcsId: `${product.idProduto}`,
                        name: product.nome,
                        brand: product.marca,
                        fullPrice: '',
                        price: product.valorUnitario,
                        categoryDetails: [
                            {
                                id: product.idCategoria,
                                name: product.categoria
                            },
                            {
                                id: product.idSubcategoria,
                                name: product.subcategoria
                            }
                        ],
                        category: `${product.categoria}/${product.subcategoria}`,
                        thumbnail: product.thumbnail,
                        fullImage: product.thumbnail,
                        quantity: product.quantidade,
                        adjustment: product.descontoItem
                    })
                } else {
                    formatedProduct.push({
                        id: product.sku,
                        sku: product.sku,
                        wcsId: `${product.idProduto}`,
                        name: product.nome,
                        brand: product.marca,
                        fullPrice: '',
                        price: product.valorUnitario,
                        categoryDetails: getProductCategoryDetailsData(product),
                        category: product.categoria,
                        thumbnail: product.imagem?.url150 || '',
                        fullImage: product.imagem?.url360 || '',
                        quantity: product.quantidade,
                        adjustment: product.descontoItem
                    })
                }
                return formatedProduct
            },
            []
        )
    } catch (error) {
        console.warn(error)
    }
}

export const formatBannersForHome = (banners: CampaignData[]) => {
    try {
        return banners.map((banner, index) => ({
            banner_index: index + 1,
            banner_name: banner.destinoClick,
            banner_id: banner.nome,
            banner_url: banner.link,
            banner_creative: banner.acaoClick,
            banner_position: banner.secao,
            banner_image_base: new URL(banner.imagem.url).origin,
            banner_image: banner.imagem.url.replace(
                new URL(banner.imagem.url).origin,
                ''
            ),
            banner_element: banner.imagem.descricao
        }))
    } catch (error) {
        console.warn(error)
        return [EMPTY_GTM_FORMATTED_BANNER]
    }
}

export const formatTransactionNetSubtotal = (orderData: Order | null) => {
    if (orderData?.valorTotalProdutos && orderData?.valorTotalDescontos) {
        return orderData?.valorTotalProdutos - orderData.valorTotalDescontos
    } else {
        return null
    }
}
